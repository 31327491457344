import logo from './assets/img/1.png';
import './assets/css/App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>На сайте идут работы по обновлению! Для покупки обращайтесь на почту: <a href = 'mailto://admin@uninode.de'>admin@uninode.de</a></p>
            </header>
        </div>
    );
}

export default App;